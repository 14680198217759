import {APIBase} from "nate-react-api-helpers";
import {WriterAPI} from "./WriterAPI";
import {FilesAPI} from "./FilesAPI";
import {RunAPI} from "./RunAPI";
import {CompanyAPI} from "./CompanyAPI";

class API extends APIBase {
    writer = new WriterAPI(this)
    runner = new RunAPI(this);
    files = new FilesAPI(this);
    company = new CompanyAPI(this);
    baseUrl = window.location.hostname === "localhost" ? "http://localhost:8080" : window.location.protocol + "//" + window.location.host

    constructor() {
        super({
            jwtRefreshEndpoint: "/api/auth/refresh",
            apiVersion: "v1",
        });

        this.fetcher.preflight.push((input) => {
            if(window.location.hostname === "localhost") {
                input.path = "http://localhost:8080" + input.path;
                return input;
            }

            return input;
        })
    }

    ping() {
        return this.fetcher.get("/api/ping", {})
    }

    logout() {
        window.location.href = "/api/auth/logout"
    }
}

export const api = new API();