import {Button, Typography} from "@mui/material";
import {api} from "../../../../api/API";
import React, {useContext} from "react";
import {WriterContext} from "../../WriterProvider";
import {useNavigate} from "react-router-dom";
import {PromptContext} from "../../../../misc/PromptProvider";

export function SettingsTab(props: {
    dir: number;
    file: number
}) {
    const wrCtx = useContext(WriterContext);
    const nav = useNavigate();
    const prompt = useContext(PromptContext)

    return (
        <>
            <Typography variant={"h6"}>
                Danger Zone
            </Typography>
            <div style={{height: 8}}/>
            <Button color="error" size="small" variant="outlined" onClick={async () => {

                const fileNode = wrCtx.items.find(i => i.id === props.file)
                const ok = await prompt.confirm("Are you sure you want to archive this item?")
                if (ok && fileNode) {
                    try {
                        await api.writer.upsertNode(Object.assign({}, fileNode, {
                            archived: true,
                        }))

                        wrCtx.reload();
                        nav("/writer/" + props.dir)
                    } catch (e: any) {
                        prompt.alert("Error", e.message)
                    }
                }

            }}>
                Delete Test
            </Button>
        </>
    )
}