import {CircularProgress, Divider, Tab, Tabs, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef} from "react";
import {detailContainerCss} from "../runs/NewRun";
import { InstructionsTab } from "./filePage/instructionsTab/InstructionsTab";
import { RobotTab } from "./filePage/robotTab/RobotTab";
import { RunsTab } from "./filePage/runsTab/RunsTab";
import {SettingsTab} from "./filePage/settingsTab/SettingsTab";
import {useSearchParam} from "../../misc/useSearchParam";
import {WriterContext} from "./WriterProvider";
import {FileStatus} from "./filePage/FileStatus";
import {CheckCircle, Warning} from "@mui/icons-material";
import {deepOrange, green} from "@mui/material/colors";
import { RightActions } from "./filePage/RightActions";

export function FilePage() {
    const params = useParams<{dir: string, file: string}>();
    const [tab, setTab] = useSearchParam("t", "");

    const nodeId = parseInt(params.file || "0");
    const dir = parseInt(params.dir || "0")
    const wrCtx = useContext(WriterContext)
    const status = wrCtx.compileStatus(nodeId)

    const wrCtxReloadRef = useRef(wrCtx.reload)
    wrCtxReloadRef.current = wrCtx.reload

    useEffect(() => {
        if(status !== "pending") return

        const int = setInterval(() => {
            wrCtxReloadRef.current()
        }, 5000)

        return () => clearInterval(int)
    }, [status]);

    return (
        <>
            <div style={{display: "flex", alignItems: "flex-end"}}>
                <div>
                    <Typography variant={"h6"} style={{padding: 8, margin: 0, paddingBottom: 0}}>
                        {wrCtx.fullNameForId(nodeId) || "-"}
                    </Typography>
                    <FileStatus file={nodeId} />
                    <div style={{height: 8}}/>
                </div>

                <div style={{width: 24}} />

                <Tabs value={tab || "instructions"} onChange={(e, value) => setTab(value)}>
                    <Tab label={"Instructions"} value={"instructions"} />
                    <Tab label={
                        <div style={{display: "flex", alignItems: "center"}}>
                            {status === "failed" ? <>
                                <Warning style={{color: deepOrange["600"], fontSize: 16}} />
                            </> : status === "success" ? <>
                                <CheckCircle style={{color: green["600"], fontSize: 16}} />
                            </> : status === "pending" ? <>
                                <CircularProgress size={16} />
                            </> : null}
                            <div style={{paddingLeft: 4}}>Robot</div>

                        </div>
                    } value={"robot"} />
                    <Tab label={"Runs"} value={"runs"} />
                    <Tab label={"Settings"} value={"settings"} />
                </Tabs>

                <div style={{flex: 1}} />

                <RightActions file={nodeId} />
            </div>
            <Divider />
            <div className={detailContainerCss} style={{flex: 1, overflow: "hidden", display: "flex", flexDirection: "column"}}>

                {tab === "runs" ? <RunsTab file={nodeId} /> :
                    tab === "robot" ? <RobotTab file={nodeId} /> :
                    tab === "settings" ? <SettingsTab file={nodeId} dir={dir} /> :
                    <InstructionsTab file={nodeId} dir={dir} />}

            </div>

        </>
    )
}