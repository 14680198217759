import {BuildInfo} from "./BuildInfo";
import React, {useContext, useEffect, useRef} from "react";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {WriterContext} from "../../WriterProvider";

export function RobotTab(props: {
    file: number
}) {

    const wrCtx = useContext(WriterContext)
    const loader = useAsync2((input) => {
        return api.writer.getFileDetail(input)
    }, {
        node: props.file,
    }, [props.file]);
    const loaderReloadRef = useRef(loader.reload)
    loaderReloadRef.current = loader.reload

    const status = wrCtx.compileStatus(props.file)
    const lastStatus = useRef(status)

    useEffect(() => {
        if(status !== lastStatus.current) {
            loaderReloadRef.current()
            lastStatus.current = status
        }

        if(status === "pending") {
            const int = setInterval(() => {
                loaderReloadRef.current()
            }, 5000)

            return () => clearInterval(int)
        }
    }, [status]);

    const file = loader.result;

    return (
        <>
            <BuildInfo value={file} onUpdate={() => {
                loader.reload()
                wrCtx.reload();
            }} />
        </>
    )
}