import {Button, ButtonGroup, Grid, Menu, MenuItem} from "@mui/material";
import {ArrowDropDown, OpenInNew} from "@mui/icons-material";
import React, { useContext } from "react";
import { WriterContext } from "../WriterProvider";
import { useNavigate } from "react-router-dom";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {loadCompileProgressEmitter} from "../sidebar/CompileProgress";

export function RightActions(props: {
    file: number;
}) {

    const wrCtx = useContext(WriterContext);
    const status = wrCtx.compileStatus(props.file)
    const nav = useNavigate();

    const compile = useAsyncAction(async (input) => {
        await api.writer.compileInstructions(input)
        loadCompileProgressEmitter.emit(true);
        wrCtx.reload()
    }, []);

    const [loadInstructionsPopover, setLoadInstructionsPopover] = React.useState<HTMLElement | null>(null);

    return (
        <div style={{padding: 8}}>
            <Grid container spacing={1}>
                {status === "success" && <Grid item>
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<OpenInNew />}
                        onClick={() => {
                            nav("/runs/new")
                        }}>
                        Run Test Suite
                    </Button>
                </Grid>}
                <Grid item>
                    <ButtonGroup variant="outlined"
                                 size="small">
                        <Button
                            disabled={status === "pending"}
                            onClick={() => {
                                compile.callback({
                                    node: props.file,
                                })
                            }}>{status === "failed" ? "Reload Instructions" : "Load Instructions"}</Button>
                        <Button onClick={(e) => setLoadInstructionsPopover(e.currentTarget)}>
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>
                    {loadInstructionsPopover && (<Menu anchorEl={loadInstructionsPopover} open onClose={() => {
                        setLoadInstructionsPopover(null)
                    }}>
                        <MenuItem onClick={() => {
                            compile.callback({
                                node: props.file,
                            })

                            setLoadInstructionsPopover(null)
                        }}>Load Normal</MenuItem>
                        <MenuItem onClick={() => {
                            compile.callback({
                                node: props.file,
                                useCached: true,
                            })
                            setLoadInstructionsPopover(null)
                        }}>Load with Cache</MenuItem>
                    </Menu>)}
                    {(compile.loading || compile.error) && compile.LoadingElement}
                </Grid>
                {status === "pending" && <Grid item>
                    <Button variant="outlined" size="small" onClick={() => {
                        compile.callback({
                            node: props.file || 0,
                            cancel: true,
                        })
                    }}>Cancel Load</Button>
                </Grid>}
            </Grid>
        </div>
    )
}