import {ForwardedRef, forwardRef, useEffect, useState} from "react";

export const MyTextarea = forwardRef(function(props: {
    value: string;
    className?: string;
    onKeyDown?(e: any): void
    placeholder?: string
    onChange?(e: any): void;
}, ref: ForwardedRef<HTMLTextAreaElement>) {

    const [textArea, setTextArea] = useState<HTMLTextAreaElement | null>(null)

    useEffect(() => {
        if(!textArea) return;

        const callback = () => {
            textArea.style.height = "";
            textArea.style.height = textArea.scrollHeight + "px"
        }

        textArea.addEventListener("change", callback)
        textArea.addEventListener("input", callback)
        callback();

        return () => {
            textArea.removeEventListener("change", callback)
            textArea.removeEventListener("input", callback)
        }
    }, [textArea])

    return (
        <textarea ref={r => {
            if(ref && typeof ref === 'function') {
                ref(r)
            } else if(ref) {
                ref.current = r
            }

            setTextArea(r)
        }}
            value={props.value}
            className={props.className}
            style={{
              fontFamily: "inherit",
            }}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeholder}
            onChange={props.onChange}
        />
    )
})