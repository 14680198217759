import {Button, Typography} from "@mui/material";
import React from "react";

export function WritingGuide() {
    const [more, setMore] = React.useState(false);

    return (
        <>
            <Typography variant={"h6"}>
                Test Writing Guide
            </Typography>

            <Typography variant={"body1"}>
                Write general, descriptive instructions the way you would write them for another
                tester.
            </Typography>
            <Typography variant={"body1"}>
                You can be specific:
            </Typography>
            <ul>
                <li><Typography variant={"body2"}>Click the "Submit" button</Typography></li>
                <li><Typography variant={"body2"}>Enter "test" in the search bar</Typography></li>
                <li><Typography variant={"body2"}>Click on the home icon</Typography></li>
            </ul>
            <Typography variant={"body1"}>You can also be general:</Typography>
            {more ? <>
            <ul>
                <li><Typography variant={"body2"}>Go to the user's section</Typography></li>
                <li><Typography variant={"body2"}>Search for XYZ</Typography></li>
                <li><Typography variant={"body2"}>Create a new user account (when you're in the user's section)</Typography></li>
                <li><Typography variant={"body2"}>Fill out the setup form</Typography></li>
            </ul>
            <Typography variant={"body1"}>Or general with some specifics:</Typography>
            <ul>
                <li><Typography variant={"body2"}>Fill out the registration form using "jim" as the username.</Typography></li>
            </ul>
                <Typography variant={"body1"}>When in doubt, be descriptive:</Typography>
                <ul>
                    <li><Typography variant={"body2"}>Click the "Submit" button located at the bottom of the page</Typography></li>
                    <li><Typography variant={"body2"}>Enter "test" into the search bar at the top of the page</Typography></li>
                    <li><Typography variant={"body2"}>Click on the icon that looks like a house</Typography></li>
                </ul>
                <ul>
                    <li>
                        <Typography variant={"body1"}>
                            Use double quotes when you want to match a specific value.
                        </Typography></li>
                    <li>
                        <Typography variant={"body1"}>
                            By default, Taxi will submit forms that you ask it to interact with. If you don't want
                            it to submit the form yet, you can say something like "... but don't submit it yet."
                        </Typography>
                    </li>
                </ul>
                <Typography variant={"body1"}>
                    Questions? Email us at <a href="mailto:nbosscher@blue-giraffe.ca">nbosscher@blue-giraffe.ca</a>
                </Typography>
            </> : <>...</>}

            {more ? <Button onClick={() => setMore(!more)}>Show Less</Button> : <Button onClick={() => setMore(!more)}>Show More</Button>}
        </>
    )
}