import {useAsync2} from "nate-react-api-helpers";
import {api} from "../api/API";
import {csvDecode} from "./writer/rootPage/Logins";
import {createContext, PropsWithChildren, useContext} from "react";
import {UDT} from "../api/WriterAPI";

function last<T>(input: T[]) {
    if(input.length === 0) return null;
    return input[input.length - 1];
}

export function useLogins() {
    return useContext(LoginsContext)
}

const LoginsContext = createContext<{
    loading: boolean;
    error: any;
    result: {
        file: UDT | undefined;
        data: string[][];
    } | null;
    reload(): void;
    LoadingOrErrorElement: any;
}>({
    loading: false,
    error: "invalid context",
    result: null,
    reload: () => {},
    LoadingOrErrorElement: null,
});

export function LoginsProvider(props: PropsWithChildren<{}>) {
    const result = useAsync2(async () => {
        const files = await api.writer.listUdt()
        const loginFile = last(files.filter(f => f.internalTag === "login"))
        if(!loginFile) return null;

        const req = await api.writer.downloadUdt({id: loginFile.id})
        const csv = await req.text();
        if(req.headers.get("content-type")?.indexOf("text/csv") === -1) throw new Error(csv)

        return {
            file: loginFile || undefined,
            data: csvDecode(csv),
        }
    }, {}, []);

    return (
        <LoginsContext.Provider value={result}>
            {props.children}
        </LoginsContext.Provider>
    )
}