import React, {PropsWithChildren} from "react";
import {Sidebar} from "./Sidebar";
import {PromptProvider} from "./misc/PromptProvider";
import {useAuthenticated} from "nate-react-api-helpers";
import {LoginsProvider} from "./pages/LoginsProvider";

export function Container(props: PropsWithChildren<{}>) {

    return (
        <LoginsProvider key={Date.now().toString()}>
            <PromptProvider>
                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "stretch",
                    overflow: "hidden",
                    flexWrap: "nowrap",
                }}>
                    <Sidebar />
                    <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "auto"}}>
                        {props.children}
                    </div>
                </div>
            </PromptProvider>
        </LoginsProvider>
    )
}