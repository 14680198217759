import {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function BaseUrl(props: {
    value: string;
    onChange(value: string): Promise<any>;
}) {
    const [startUrl, setStartUrl] = useState("")
    const [urlExpanded, setUrlExpanded] = useState(false)

    useEffect(() => {
        setStartUrl(props.value)
        setUrlExpanded(!props.value)
    }, [props.value]);

    const update = useAsyncAction(async (startUrl) => {
        await props.onChange(startUrl)
    }, []);

    return (
        <Accordion expanded={urlExpanded} onChange={(_, exp) => setUrlExpanded(exp)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography variant="h6">
                    App Start URL
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={e => {
                    e.preventDefault()
                    update.callback(startUrl)
                }}>
                    <Typography variant="body2">
                        What is the URL of the app we're working on? <br/>
                        This should be a staging or testing environment.
                    </Typography>

                    <div style={{height: 16}}/>

                    <TextField
                        label="URL"
                        variant="outlined" size="small" fullWidth
                        value={startUrl} onChange={e => setStartUrl(e.target.value)}
                        type="url"
                        helperText="e.g. https://staging.myapp.com"
                    />

                    <div style={{height: 0}}/>

                    <Grid container spacing={1}>
                        <Grid item xs/>
                        <Grid item>
                            {update.LoadingElement}
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" size="small" type="submit">
                                {props.value ? "Update" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
    )
}