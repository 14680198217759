import {CompiledStep} from "../../../../api/WriterAPI";
import {Accordion, AccordionDetails, AccordionSummary, capitalize} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function CompiledInstructions(props: {
    value: CompiledStep[] | null | undefined
}) {
    return (
        <div style={{overflow: "auto"}}>
            {props.value?.map(v => {
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {capitalize(v.type)}
                        </AccordionSummary>
                        <AccordionDetails>
                            {cleanPassword(v.description)}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}

export function cleanPassword(desc: string) {
    if(desc.toLowerCase().indexOf("password") === -1) return desc;
    return desc.replace(/(['"]).+?(['"])/g, "$1******$2")
}
