import {Alert, Button, Grid} from "@mui/material";
import {formCss} from "../../DirPage";
import {InstructionList} from "./InstructionList";
import {WritingGuide} from "./WritingGuide";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {InstructionWithUuid} from "./InstructionRow";
import {WriterContext} from "../../WriterProvider";
import {useNavigate} from "react-router-dom";

export function InstructionsTab(props: {
    file: number;
    dir: number;
}) {
    const wrCtx = useContext(WriterContext);

    const reloadRef = useRef(wrCtx.reload)
    reloadRef.current = () => {
        wrCtx.reload()
        loader.reload();
    };

    const loader = useAsync2((input) => {
        return api.writer.getFileDetail(input)
    }, {
        node: props.file,
    }, [props.file]);
    const file = loader.result;

    const [instructions, setInstructions] = useState<InstructionWithUuid[]>([])

    useEffect(() => {
        setInstructions((file?.instructions || []).map(i => Object.assign({}, i, {
            uuid: window.crypto.randomUUID(),
        })))
    }, [file]);

    const [showLoadRobotWarning, setShowLoadRobotWarning] = useState(false)

    useEffect(() => {
        if(!file) return;

        if(file.instructions.length > 0 && !file.taskStatus) {
            setShowLoadRobotWarning(true)
        }
    }, [file]);

    const update = useAsyncAction(async (input) => {
        await api.writer.upsertFile(input)
        reloadRef.current();
        setShowLoadRobotWarning(true)
    }, []);

    const nav = useNavigate();

    const compile = useAsyncAction(async (input) => {
        await api.writer.compileInstructions(input)
        nav(`/writer/${props.dir}/${props.file}?t=robot`)
        reloadRef.current();
    }, []);

    const status = wrCtx.compileStatus(props.file)

    return (
        <div style={{overflow: "auto"}}>
            <Grid container flexWrap="nowrap" spacing={2}>
                <Grid item>
                    {status === "pending" ? <Alert severity="warning">
                        Can't edit instructions while Robot is loading.
                        See Robot tab for more details.
                    </Alert> : status === "success" ? <Alert severity="success">
                        Robot is loaded and ready to run.
                    </Alert> : null}

                    <form className={formCss} onSubmit={async (e) => {
                        e.preventDefault()
                        update.callback(Object.assign({}, file, {
                            instructions: instructions.map(ins => {
                                return Object.assign({}, ins, {
                                    uuid: undefined,
                                })
                            }),
                        }))
                    }}>
                        {file ? loader.error ? loader.LoadingOrErrorElement : null : loader.LoadingOrErrorElement}
                        <InstructionList value={instructions} onChange={ins => {
                            setInstructions(ins)
                        }}
                                         right={
                                             <div>
                                                 <Grid container spacing={1}>
                                                     <Grid item>
                                                         {update.LoadingElement}
                                                     </Grid>
                                                     <Grid item>
                                                         <Button variant="outlined" color="primary" size={"small"}
                                                                 type="submit">Save</Button>
                                                     </Grid>
                                                 </Grid>
                                             </div>
                                         }
                        />

                        {showLoadRobotWarning && <Alert severity="warning" style={{marginTop: 16}}>
                            Don't forget to load your Test Robot!
                            {file && <Button onClick={() => compile.callback({
                                node: file.writerNode,
                            })}>Load Test Robot</Button>}
                        </Alert>}
                    </form>
                </Grid>
                <Grid item>
                    <WritingGuide />
                </Grid>
            </Grid>
        </div>
    )
}