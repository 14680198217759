import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import React, {useState} from "react";
import moment from "moment";
import {Button, Divider, Grid, ListItemButton, Typography} from "@mui/material";
import {SuccessChip} from "./runDetail/SuccessChip";
import {grey} from "@mui/material/colors";
import {Link} from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useDarkMode} from "../../misc/UseGrey";

export function RunList() {
    const [page, setPage] = useState(0)
    const list = useAsync2((input) => api.runner.list(input), {page: page}, [page])
    const isDark = useDarkMode()
    return (
        <>
            <div style={{
                paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                paddingLeft: 8,
                display: "flex",
                alignItems: "center",
            }}>
                <Typography variant={"h5"}>
                    Test Runs
                </Typography>
                <div style={{flex: 1}} />
                <Link to="/runs/new" style={{textDecoration: "none", color: "inherit"}}>
                    <Button startIcon={<AddCircleIcon />} size={"medium"} variant="outlined" color="primary">
                        Start Run
                    </Button>
                </Link>
            </div>
            <Divider />
            {list.LoadingOrErrorElement}
            <div style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                flex: 1,
            }}>
            {list.result?.data.map(r => (
                <Link key={r.id} to={"/runs/" + r.companySeqNum} style={{textDecoration: "none", color: "inherit"}}>
                    <ListItemButton
                        style={{paddingTop: 4, paddingBottom: 4, borderBottom: "1px solid " + grey["200"]}}
                    >
                        <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                            <div style={{flex: 1}}>
                                <div style={{display: "flex"}}>
                                    <div style={{color: grey["700"], paddingRight: 10, paddingTop: 1}}>
                                        #{r.companySeqNum}
                                    </div>
                                    <div>
                                        <Typography variant="h5"
                                                    style={{alignSelf: "center", fontSize: "1.2rem",
                                                        display: "flex", alignItems: "flex-start",
                                                        color: isDark ? grey["200"]: grey["800"],
                                        }}>
                                            {r.startedAt ? <>
                                                {moment.utc(r.startedAt).format(dateFormat)}
                                                <div style={{
                                                    fontSize: "0.7rem", marginLeft: 4,
                                                    background: isDark ? grey["800"] : grey["200"],
                                                    padding: 1, borderRadius: 4,
                                                    paddingTop: 2,
                                                    paddingLeft: 3, paddingRight: 3,
                                                }}>UTC</div>
                                            </> : "Pending Run"}


                                        </Typography>

                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Typography variant="body2" style={{color: grey["600"]}}>
                                                    {r.pendingCount} Pending
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{color: grey["600"]}}>
                                                    {r.errorCount} Failed
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{color: grey["600"]}}>
                                                    {r.successCount} Passed
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{color: grey["600"]}}>
                                                    Duration {formatDur(r.durationMs)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding: 8}}>
                                <SuccessChip status={r.status === "pending" ? r.status : r.errorCount > 0 ? "failed" : "success"} />
                            </div>
                        </div>
                    </ListItemButton>
                </Link>
            ))}

                <Grid container spacing={1}>
                    <Grid item>
                        <Button disabled={page <= 0} onClick={() => {
                            setPage(page-1)
                        }}>
                            Prev Page
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => {
                            setPage(page+1)
                        }}>
                            Next Page
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export const dateFormat = "MMM DD [at] HH:mm"

export function formatDur(inputMs: number) {
    const sec = inputMs / 1000;
    if(sec < 60) {
        return sec.toFixed(1) + "s"
    }

    const min = sec / 60;
    if(min < 60) {
        return Math.floor(min) + "m " + (sec % 60).toFixed(0) + "s"
    }

    const hours = min / 60;
    return Math.floor(hours) + "h " + (min % 60).toFixed(0) + "m"
}