import {TaskStatus} from "../../../../api/WriterAPI";
import {deepOrange, green, grey} from "@mui/material/colors";

export function TaskStatusIndicator(props: {
    value: TaskStatus;
    customValue?: string;
    customBg?: any;
}) {

    let bgColor: string = grey["200"]
    let text: any = "unknown";

    switch(props.value) {
        case "failed":
            text = "Error"
            bgColor = deepOrange["200"]
            break;
        case "pending":
            text = "Pending"
            bgColor = green["200"]
            break;
        case "cancelled":
            text = "User Cancelled"
            bgColor = grey["200"]
            break;
        case "success":
            text = "Ready to Run"
            bgColor = green["200"]
            break;
    }

    return (
        <div style={{
            backgroundColor: props.customBg || bgColor,
            borderRadius: 4,
            fontSize: "0.7rem",
            display: "inline-block",
            padding: "0px 4px",
        }}>
            {props.customValue || text}
        </div>
    )
}