import {
    Button,
    List,
    ListItemButton, ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import Zoom from "react-medium-image-zoom";
import MouseIcon from '@mui/icons-material/Mouse';
import ChecklistIcon from '@mui/icons-material/Checklist';
import NavigationIcon from '@mui/icons-material/Navigation';
import WarningIcon from '@mui/icons-material/Warning';
import {css} from "@emotion/css";
import {useWindowSize} from "../../../../misc/useWindowSize";
import {DebugLogItem} from "../../../../api/WriterAPI";
import {api} from "../../../../api/API";

export function DebugLog(props: {
    value: DebugLogItem[] | null | undefined
    compileDone: boolean;
    taskBuildVideo?: number;
}) {
    const [selected, setSelected] = useState<DebugLogItem|null>(null);
    const list = useMemo(() => props.value?.filter(v => {
        return !!v.Form || !!v.Click || !!v.UserChunk || !!v.UserError
    }) || [], [props.value])

    const listPws = list.filter(v => !!v.UserChunk).map(v => {
        // extract the password from the UserChunk
        const pw = v.UserChunk?.match(/with password "(.*)"/)?.[1]
        return pw ? pw : null
    })

    useEffect(() => {
        if(list.length === 0) return;
        if(selected) return;

        setSelected(list[list.length-1])
    }, [selected, list]);

    // if the last item is more than 1 minute old, show a message
    const isDelay = list.length > 0 && new Date(list[list.length-1].Timestamp).getTime() < Date.now() - 3 * 60 * 1000
    const win = useWindowSize();

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            overflow: "hidden",
            flex: 1,
        }}>
            <div style={{width: 350, display: "flex", flexDirection: "column"}}>
                <Typography variant="h6">Robot Log</Typography>
                <Typography variant="body2">Click an item to view more details</Typography>
                <Typography variant="body2">This will take a while. Feel free to close this page and/or work on other tests.</Typography>

                <List dense style={{overflow: 'auto', flex: 1}}>
                    {list.map((v, index) => {
                        const isUserStep = !!v.UserChunk;
                        const isError = !!v.UserError
                        const isClick = !!v.Click
                        const isForm = !!v.Form

                        return (
                            <ListItemButton
                                selected={v.Timestamp === selected?.Timestamp}
                                onClick={() => setSelected(v)}
                                style={{
                                    paddingLeft: isUserStep ? undefined : 40,
                                    color: isError ? "red" : undefined
                                }}
                            >
                                <ListItemIcon>
                                    {isClick ? <MouseIcon />
                                        : isForm ? <ChecklistIcon /> :
                                            isUserStep ? <NavigationIcon /> :
                                                isError ? <WarningIcon />
                                        : null}
                                </ListItemIcon>
                                <ListItemText
                                    primary={sanitizePws(v.Form || v.Click || v.UserChunk || v.UserError || "", listPws)}
                                    secondary={simplifyUrl(v.Url, index === 0 ? null : list[index-1].Url)}
                                    classes={{
                                        secondary: secondaryCss,
                                    }}
                                />
                            </ListItemButton>
                        )
                    })}
                </List>

                {isDelay && !props.compileDone && <Typography variant="body2">Optimizing actions... (this may take a while)</Typography>}
                {props.taskBuildVideo && <Button variant="outlined" onClick={() => {
                    if(!props.taskBuildVideo) return;
                    window.open(api.files.makeUrl(props.taskBuildVideo))
                }}>
                    View Session Video
                </Button>}
            </div>

            {selected?.S3ScreenshotId && <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                <Zoom>
                    <img style={{width: "100%", height: "100%", objectFit: "contain"}} alt="debug screenshot" src={api.files.makeUrl(selected?.S3ScreenshotId)}/>
                </Zoom>
            </div>}
        </div>
    )
}

function simplifyUrl(url: string, prevUrl: string | null) {
    if(!prevUrl) return url;
    try {
        const u = new URL(url)
        const p = new URL(prevUrl)

        if (u.origin !== p.origin) return url;

        return u.pathname + u.search + u.hash
    } catch (e) {
        return url;
    }
}

const secondaryCss = css({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
})

function sanitizePws(input: string, list: (string | null)[]) {
    let output = input;

    list.forEach(pw => {
        if (pw) {
            output = output.replace(pw, "********")
        }
    })

    return output
}