import {BaseUrl} from "./rootPage/BaseUrl";
import {Alert, AlertTitle, Typography} from "@mui/material";
import {detailContainerCss} from "../runs/NewRun";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import { Logins } from "./rootPage/Logins";

export function RootPage() {
    const result = useAsync2(() => {
        return api.writer.getProjectConfig()
    }, {}, []);
    const config = result.result;
    const startUrl = config?.startUrl;

    return (
        <div className={detailContainerCss}>
            <Typography variant="h6">Welcome to Taxi Writer!</Typography>
            <div style={{height: 10}}/>
            <Typography variant="body2">
                Let's create a few tests and load them up in our robot!<br/>
                Load them once, run them over and over.
            </Typography>
            <div style={{height: 16}}/>
            <div style={{width: 450}}>
                {config && <BaseUrl value={config.startUrl || ""} onChange={value => {
                    return api.writer.updateProjectConfig(Object.assign({}, config, {
                        startUrl: value,
                    }))
                }}/>}

                <Logins />

                {startUrl && <>
                    <div style={{height: 16}}/>
                    <Alert severity="success">
                        <AlertTitle>Setup your Test Plans</AlertTitle>
                        <ol style={{paddingLeft: "1.5em"}}>
                            <li>Use the file explorer on the left to create your tests.</li>
                            <li>Use the Test Runs section to run your test plans</li>
                        </ol>
                    </Alert>
                </>}

                <div style={{height: 16}}/>

                <Typography variant="body2">
                    Special setup requirements? <br/>Get assistance at <a
                    href="mailto:onboarding@testingtaxi.com">onboarding@testingtaxi.com</a>.
                    In the mean time, you can keep going.
                </Typography>
            </div>
        </div>)
}