import React, {PropsWithChildren, useContext} from 'react';
import MuiTableCell from '@mui/material/TableCell';
import {
    AutoSizer,
    Column,
    Index,
    Table,
    TableCellRenderer,
    TableHeaderProps
} from 'react-virtualized';
import {Button, IconButton, TextField} from '@mui/material';
import {Delete, Edit} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {PromptContext} from "../../../misc/PromptProvider";

function TableCell(props: PropsWithChildren<{
    style?: React.CSSProperties;
    onClick?(): void;
}>) {
    return (
        <MuiTableCell
            component="div"
            sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                boxSizing: 'border-box',
                cursor: 'initial',
                height: ROW_HEIGHT
            }}
            variant="body"
            style={props.style}
            onClick={props.onClick}
        >
            {props.children}
        </MuiTableCell>
    );
};

const ROW_HEIGHT = 32;

type ColumnSpec = {
    index: number;
    name: string;
    cellRenderer: TableCellRenderer;
    type?: "password"
    actions?: boolean;
    width?: number;
}

function VirtualizedTable(props: {
    columns: string[]
    value: string[][]
    customizeColumn?(input: ColumnSpec): void
    onChange(value: string[][]): void
    onAddBlank(): void;
}) {
    const handleEditCell = (e: any, rowIndex: number, cellIndex: number) => {
        props.onChange(props.value.map((row, index) => {
            if (index === rowIndex) {
                const r = row.slice(0)
                r[cellIndex] = e.target.value;
                return r
            }

            return row;
        }))
    };

    const prompt = useContext(PromptContext)

    const simpleCellRenderer: TableCellRenderer = ({ dataKey, rowIndex, cellData, rowData }) => {

        return (
            <TableCell>
                {dataKey === "actions" ?
                    rowIndex === editingRowIndex ? <IconButton color="primary" onClick={() => {
                        setEditingRowIndex(null)
                    }}>
                        <CheckCircleIcon />
                    </IconButton> :
                    (
                        <>
                            <IconButton style={{opacity: 0.7}} size="small" onClick={async () => {
                                const ok = await prompt.confirm("Are you sure you want to delete this row?")
                                if (!ok) return;

                                const list = props.value.slice(0)
                                list.splice(rowIndex, 1)
                                props.onChange(list)
                            }}>
                                <Delete />
                            </IconButton>
                            <IconButton style={{opacity: 0.7}} size="small" onClick={() => setEditingRowIndex(rowIndex)}>
                                <Edit />
                            </IconButton>
                        </>
                ) : (
                    cellData
                )}
            </TableCell>
        );
    };

    const editableCellRenderer: TableCellRenderer = ({ rowIndex, columnIndex, cellData }) => {
        const isPassword = columns[columnIndex].type === "password"
        return (
            <TableCell style={{
                cursor: "pointer", width: "100%",
            }} onClick={() => {
                if(rowIndex !== editingRowIndex) {
                    setEditingRowIndex(rowIndex)
                }
            }}>
                {rowIndex === editingRowIndex ? (
                    <TextField
                        size="small"
                        variant="standard"
                        type={isPassword ? "password" : undefined}
                        InputProps={{ disableUnderline: true, style: { fontSize: 'inherit' } }}
                        value={cellData}
                        onChange={(e) => handleEditCell(e, rowIndex, columnIndex)}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setEditingRowIndex(null)
                            }
                        }}
                    />
                ) : (
                    isPassword ? "•".repeat(8) : cellData
                )}
            </TableCell>
        );
    };

    const columns: ColumnSpec[] = props.columns.map((v, index) => ({
        index: index,
        name: v,
        cellRenderer: editableCellRenderer
    }))

    columns.push({
        index: columns.length,
        name: "",
        cellRenderer: simpleCellRenderer,
        actions: true,
        width: 180,
    })

    if(props.customizeColumn) {
        columns.forEach(props.customizeColumn)
    }

    const headerRenderer = ({ label }: TableHeaderProps) => <TableCell style={{fontWeight: "600"}}>{label}</TableCell>;

    const [editingRowIndex, setEditingRowIndex] = React.useState<number | null>(null);

    const getRowStyles = ({ index }: Index): React.CSSProperties => {
        const isEdit = Boolean(editingRowIndex === index);

        return {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            boxShadow: isEdit
                ? '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                : 'none'
        };
    };

    return (
        <>
            <div style={{flex: 1}}>
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            height={height}
                            width={width}
                            rowHeight={ROW_HEIGHT!}
                            headerHeight={ROW_HEIGHT!}
                            rowClassName="row"
                            rowStyle={getRowStyles}
                            rowCount={props.value.length}
                            rowGetter={({ index }) => props.value[index]}
                        >
                            {columns.map((col, index) => {
                                return (
                                    <Column
                                        style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box' }}
                                        key={index.toString()}
                                        headerRenderer={headerRenderer}
                                        cellRenderer={col.cellRenderer}
                                        width={col.width || 250}
                                        dataKey={col.actions ? "actions" : index.toString()}
                                        label={col.name}
                                    />
                                );
                            })}
                        </Table>
                    )}
                </AutoSizer>
            </div>
            <div style={{
                display: "flex", justifyContent: "flex-start", paddingLeft: 8,
            }}>
                <Button size="small" onClick={() => {
                    props.onAddBlank()
                }}>Add Row</Button>
            </div>
        </>
    );
};

export default VirtualizedTable;