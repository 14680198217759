import {Alert, Button, CircularProgress, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {Link, useParams} from "react-router-dom";
import {blueGrey, grey} from "@mui/material/colors";
import React, {useEffect, useRef, useState} from "react";
import {RunItem} from "../../api/RunAPI";
import {TestList} from "./runDetail/TestList";
import {TestDetails} from "./runDetail/TestDetails";
import {useSearchParam} from "../../misc/useSearchParam";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ReplayIcon from '@mui/icons-material/Replay';
import {useDarkMode} from "../../misc/UseGrey";

export function RunDetail() {
    const params = useParams<{run: string}>();
    const runId = parseInt(params.run || "0")

    const [selected, setSelected] = useState<RunItem>();
    const [runItemOffset, setRunItemOffset] = useState(0);
    const results = useAsync2(
        (input) => api.runner.getItems(input),
        {runSeqNum: runId, offset: runItemOffset}, [runId, runItemOffset])

    const [searchSelected, setSearchSelected] = useSearchParam("v", "");
    const list = results.asList;

    const getRun = useAsync2((input) => api.runner.get(input), {seqNum: runId}, [runId])

    const runErr = getRun.error;
    const resultErr = results.error;
    const retryCountRef = useRef(0)
    const errReloadingRef = useRef(false)

    useEffect(() => {
        errReloadingRef.current = false;
        if(retryCountRef.current > 3) return;

        if(runErr || resultErr) {
            retryCountRef.current++
            errReloadingRef.current = true;

            const tm = setTimeout(() => {
                getRun.reload();
                results.reload();
            }, 1000);

            return () => {
                clearTimeout(tm);
            }
        }
    }, [runErr, resultErr]);

    const hasPending = list.find(d => d.status === "pending");
    useEffect(() => {
        if(!hasPending) return;
        const tm = setTimeout(() => {
            results.reload();
        }, 5000)

        return () => clearTimeout(tm);
    }, [hasPending]);

    const isDark = useDarkMode();

    useEffect(() => {
        if(list.length === 0) return;

        if(searchSelected != selected?.id.toString()) {
            const selected = list.find(i => i.id.toString() === searchSelected)
            if(selected) {
                setSelected(selected)
            }
        }
    }, [searchSelected, list, selected]);

    return (
        <div style={{
            flex: 1, display: "flex", flexDirection: "row", alignItems: "stretch",
            overflow: "hidden",
        }}>
            <div style={{width: 350, borderRight: "1px solid " + grey["200"],
                overflow: "hidden",
                display: "flex", flexDirection: "column",
            }}>
                <Link to="/runs" style={{color: "inherit", textDecoration: "none"}}>
                    <Button size="small" startIcon={<ChevronLeftIcon/>}>
                        Back to Run List
                    </Button>
                </Link>
                <Divider/>
                {results.LoadingOrErrorElement}
                {list.length === 0 && getRun.result?.status === "pending" && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 10,
                }}>
                    <CircularProgress/>
                    <div style={{height: 10}}/>
                    <Typography variant="body2">
                        Your results will begin to show here shortly.
                    </Typography>
                </div>}
                {list.length === 0 && getRun.result?.status === "failed" &&
                    <Alert severity="error">
                        There was a problem running your tests.
                        Please try again or contact <a href="mailto:support@testingtaxi.com">support@testingtaxi.com</a>.
                    </Alert>}

                <div style={{
                    flex: 1,
                    overflowY: "auto",
                }}>
                    <TestList
                        results={list} selected={selected || undefined}
                        onSelect={sel => {
                            setSelected(sel)
                            setSearchSelected(sel.id.toString())
                        }}
                    />
                </div>
                <Divider />
                <div style={{
                    backgroundColor: blueGrey[isDark ? "900" : "50"]
                }}>
                    <Tooltip title={"Reload List"}>
                        <IconButton size="small" onClick={()=> {
                            results.reload()
                            getRun.reload()
                        }}>
                            <ReplayIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "column", overflowY: "auto"}}>
                {results.LoadingOrErrorElement}
                {!selected && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 10,
                }}>
                    <Typography variant="body1" style={{
                        color: grey["600"],
                    }}>Click a list item to view details</Typography>
                </div>}
                {selected && <TestDetails key={selected.id.toString()} test={selected}/>}
            </div>
        </div>
    );
}