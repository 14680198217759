import React, {useContext} from "react";
import {WriterContext} from "../WriterProvider";
import {TaskStatusIndicator} from "./robotTab/TaskStatusIndicator";
import {grey} from "@mui/material/colors";

export function FileStatus(props: {
    file: number;
}) {

    const wrCtx = useContext(WriterContext);
    const status = wrCtx.compileStatus(props.file)
    return (
        <div style={{paddingLeft: 8, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            {status === "success" ?
                <TaskStatusIndicator value={status} /> :
                status === "pending" ?
                    <>
                        <TaskStatusIndicator value={status} customValue="Loading robot..." />
                    </> :
                    status === "failed" ?
                        <>
                            <TaskStatusIndicator value={status} />
                        </>
                    : <>
                            <TaskStatusIndicator value="pending" customBg={grey["200"]} customValue="Draft" />
                    </>
            }
        </div>
    )
}