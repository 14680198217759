import {deepOrange} from "@mui/material/colors";
import {FileDetail} from "../../../../api/WriterAPI";
import React, {useRef} from "react";
import {Alert, Typography} from "@mui/material";
import { CompiledInstructions } from "./CompiledInstructions";
import {DebugLog} from "./DebugLog";

export function BuildInfo(props: {
    value: FileDetail | null
    onUpdate(): void;
}) {
    const f = props.value;
    const loading = f === null ? null : !!f.currentTask && f?.taskStatus === "pending";
    const needsInstructions = props.value?.instructions.length === 0
    const onUpdateRef = useRef(props.onUpdate)
    onUpdateRef.current = props.onUpdate;

    return (
        <>
            {!loading && needsInstructions &&
                <Typography variant={"body2"} color={deepOrange["700"]}>
                    Create and save your instructions first.
                </Typography>}

            {f && !f?.taskStatus && <>
                <Typography variant={"body2"}>
                    Click "Load Instructions" to train the Test Robot on this task
                </Typography>
            </>}
            {f?.taskStatus === "failed" && <Alert severity="error">
                Failed to load instructions. Here are a few things to check:
                <div>
                <ol style={{paddingLeft: 18}}>
                    <li>Make sure your instructions can't be confused with other things on the page.</li>
                    <li>Sometimes simply running the "Load Instructions" again will fix the issue</li>
                </ol>
                </div>
            </Alert>}

            {(loading || !f?.compiledInstructions) ?
                <DebugLog value={f?.debugLog} compileDone={!loading} taskBuildVideo={f?.taskBuildVideo} /> :
                <CompiledInstructions value={f?.compiledInstructions} />
            }
        </>
    )
}