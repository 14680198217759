import React, {useContext, useRef, useState} from "react";
import {grey, red} from "@mui/material/colors";
import {css} from "@emotion/css";
import {CreateEditItem} from "./CreateEditItem";
import {FileNode} from "../../../api/WriterAPI";
import {Menu, MenuItem, useTheme} from "@mui/material";
import {PromptContext} from "../../../misc/PromptProvider";
import {api} from "../../../api/API";
import {WriterContext} from "../WriterProvider";
import {useDrag, useDrop, XYCoord} from "react-dnd";
import {useDarkMode} from "../../../misc/UseGrey";
import {useNavigate} from "react-router-dom";

const itemType = "item"
type Item = {node: FileNode} & {id: number, index: number}

export function WriterItem(props: {
    icon?: any;
    color?: string;
    title: string;
    indent?: number;
    onClick?(): void
    selected?: boolean;
    create?: boolean;
    value?: FileNode
    nodeType: FileNode["nodeType"];
    parent?: number;
    count?: number;
    countColor?: string;
    splice?(dragIndex, hoverIndex: number): void;
    onDrop?(node: FileNode): void;
}) {
    const indent = props.indent ? props.indent * 16 : 0;
    const [createMode, setCreateMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [contextMenu, setContextMenu] = useState<HTMLElement|null>(null);
    const prompt = useContext(PromptContext)
    const wrCtx = useContext(WriterContext)
    const index = props.value?.sortOrder;

    const [{isDragging}, drag] = useDrag(() => ({
        type: props.value ? itemType : "ignore",
        item: () => {
            return { id: props.value?.id, index: index, node: props.value }
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))

    const canDrop = (item: Item) => {
        if(!props.value) {
            return false
        }

        // files can be dropped on folders
        if(props.nodeType === "folder" && item.node.nodeType === "file") {
            return true
        }

        // folders can only be re-arranged
        if(props.nodeType === "file" && item.node.nodeType === "folder") {
            return false
        }

        // moving files can only be dropped on another folder or re-arranged within the same folder
        if(props.nodeType === "file" && item.node.nodeType === "file") {
            if(props.value?.parent !== item.node.parent) {
                return false
            }
        }

        return true;
    }

    const ref = useRef<HTMLButtonElement|null>(null)
    const [{ handlerId, isOver }, drop] = useDrop<
        Item,
        void,
        { handlerId: any | null, isOver: boolean }
    >({
        accept: itemType,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                isOver: monitor.isOver(),
            }
        },
        canDrop: canDrop,
        hover(item: Item, monitor) {
            if (!ref.current) {
                return
            }

            if(!canDrop(item)) return

            // don't re-arrange folder for files, files are dropped on folders
            if(props.nodeType === "folder" && item.node.nodeType === "file") {
                return
            }

            const dragIndex = item.index
            const hoverIndex = index

            if(hoverIndex === undefined || hoverIndex === null) {
                return;
            }

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            if(props.splice) {
                props.splice(dragIndex, hoverIndex)
            }

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
        drop: (item: Item) => {
            console.log("drop", item.node)

            if(props.onDrop) {
                props.onDrop(item.node)
            }
        },
    })

    const theme = useTheme()
    const isDark = useDarkMode()
    const nav = useNavigate()

    if (createMode) {
        return (<CreateEditItem indent={indent} parent={props.parent} nodeType={props.nodeType}
                                onDone={() => setCreateMode(false)} />)
    }

    if (editMode && props.value) {
        return (<CreateEditItem edit={props.value} indent={indent} parent={props.parent} nodeType={props.nodeType} onDone={() => setEditMode(false)} />)
    }

    drag(drop(ref))

    return (
        <>
            <button
                ref={ref}
                className={clsx({
                    [writerItemCss]: true,
                    [writerItemSelectedCss]: props.selected,
                })}
                style={{
                    paddingLeft: 10 + indent,
                    opacity: isDragging ? 0.5 : 1,
                    backgroundColor: isOver && props.value?.nodeType === "folder" ? theme.palette.primary.light : undefined,
                }}
                onClick={() => {
                    if(props.onClick) {
                        props.onClick();
                        return;
                    }

                    if(props.create) {
                        setCreateMode(true);
                    }
                }}
                onContextMenu={e => {
                    e.preventDefault();
                    setContextMenu(e.currentTarget)
                }}
                data-handler-id={handlerId}
            >
                <div style={{color: props.color ||
                        (isDark ? grey["300"] : grey["600"]), paddingTop: 2}}>
                    {props.icon}
                </div>
                <div style={{paddingLeft: 8, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                    color: (isDark ? grey["300"] : grey["900"])
                }}>
                    {props.title}
                </div>
                {props.count ?
                    <div style={{marginLeft: "auto", color: props.countColor || grey["700"], fontSize: "0.8rem"}}>{props.count}</div>
                    : props.countColor ? <>
                        <div style={{marginLeft: "auto", color: props.countColor, fontSize: "0.8rem"}}>●</div>
                    </> : null}
            </button>
            {contextMenu && <Menu open slotProps={{
                paper: {
                    style: {
                        minWidth: 220,
                    }
                }
            }} anchorEl={contextMenu} onClose={() => setContextMenu(null)}>
                <MenuItem onClick={() => {
                    setEditMode(true)
                    setContextMenu(null)
                }}>Rename</MenuItem>
                <MenuItem style={{color: red["800"]}} onClick={async () => {

                    const ok = await prompt.confirm("Are you sure you want to archive this item?")
                    if(ok && props.value) {
                        try {
                            await api.writer.upsertNode(Object.assign({}, props.value, {
                                archived: true,
                            }))

                            wrCtx.reload();
                            nav("/writer")
                        } catch (e: any) {
                            prompt.alert("Error", e.message)
                        }
                    }

                    setContextMenu(null)
                }}>
                    Archive
                </MenuItem>
            </Menu>}
        </>
    )
}

export function clsx(input: {[key: string]: boolean|null|undefined}) {
    return Object.keys(input).filter(k => input[k]).join(" ")
}

const writerItemSelectedCss = css({
    backgroundColor: "rgba(0, 0, 0, 0.08) !important"
})

export const writerItemCss = css({
    display: "flex",
    flexDirection: "row",
    padding: 0,
    paddingLeft: 4, paddingRight: 4,
    cursor: "pointer",
    border: "none",
    alignItems: "center",
    fontSize: "0.9rem",
    background: "none",
    borderRadius: 4,

    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.1)"
    }
})