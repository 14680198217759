import {SubAPI} from "./WriterAPI";
import {api} from "./API";

export class FilesAPI extends SubAPI {
    makeUrl(id: number) {
        return api.baseUrl + `/api/file?id=${id}`
    }

    makeUrlSimple(id: number) {
        return `/api/file?id=${id}`
    }
}