import {DateString, SubAPI, TaskStatus} from "./WriterAPI";

export class RunAPI extends SubAPI {
    newRun(input: {
        files: number[]
    }) {
        return this.fetcher.post<Run>("/api/run/start-new", input)
    }

    get(input: {
        seqNum: number;
    }) {
        return this.fetcher.get<Run>("/api/run?seqNum=" + input.seqNum)
    }

    getItems(input: {
        runSeqNum: number;
    }) {
        return this.fetcher.get<RunItem[]>("/api/run/items", input)
    }

    getHistorical(input: {
        runItem: number;
    } | {
        file: number;
    }) {
        return this.fetcher.get<TestHistory[]>("/api/run/item/history", input)
    }

    list(props: {
        page?: number
    }) {
        return this.fetcher.get<Paginated<RunSummary>>("/api/runs", props)
    }

    listTriggers() {
        return this.fetcher.get<Trigger[]>("/api/run/triggers")
    }

    upsertTrigger(input: Trigger) {
        return this.fetcher.post("/api/run/trigger", input)
    }

    listNotifications() {
        return this.fetcher.get<Notification[]>("/api/run/notifications")
    }

    upsertNotification(input: Notification) {
        return this.fetcher.post("/api/run/notification", input)
    }

    testNotification(input: Notification) {
        return this.fetcher.post("/api/run/notification/test", input)
    }
}

export type Trigger = {
    id: number;
    name: string;
    triggerType: TriggerType
    webhookUrl: string;
    webhookKey: string;
    webhookBearerToken: string;
    webhookRequireToken: boolean;
    scheduledCron: string;
    scheduledNextRun: DateString;
    archived: boolean;
}

export type TriggerType = "webhook" | "scheduled"

export type Notification = {
    id: number;
    actionType: NotificationType
    email: string;
    webhookMethod: string
    webhookUrl: string
    webhookAuthToken: string;
    archived: boolean
}

export type NotificationType = "email" | "webhook"

type Paginated<T> = {
    data: T[];
    totalCount: number;
}

export type Run = {
    id: number;
    status: TaskStatus;
    companySeqNum: number;
}

export type TStatus = "passed" | "failed" | "pending" | "success"

export type TestStep = {
    id: number;
    name: string;
    durationMs: number;
    assertions: number;
    attachments?: TestAttachment[] | null
    log: string
} & ({
    status: "passed";
} | {
    status: "failed";
    error: string;
})

export type TestAttachment = {
    type: "image";
    url: string;
} | {
    type: "file";
    url: string;
    name: string;
}

export type TestHistory = {
    id: number;
    companySeqNum: number;
    run: string;
    runItem: number;
    status: "passed" | "failed";
    durationMs: number;
    timestamp: string;
}

type TestStepGroup = {
    id: number;
    name: string;
    durationMs: number;
    steps: TestStep[] | null;
    assertions: number;
}

export type RunItem = {
    id: number;
    name: string;
    steps: TestStepGroup[] | null;
    writerUrl: string;
} & ({
    status: "failed";
    error: string
} | {
    status: "passed";
} | {
    status: "pending";
})

type RunSummary = {
    id: number
    uuid: number;
    name: string;
    companySeqNum: number;

    status: TStatus;
    startedAt: string;
    durationMs: number;

    successCount: number;
    errorCount: number;
    pendingCount: number;
}