import {useDrag, useDrop} from "react-dnd";
import {useContext, useRef, useState} from "react";
import type { Identifier, XYCoord } from 'dnd-core'
import {grey, red} from "@mui/material/colors";
import {MenuItem, Select} from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Close} from "@mui/icons-material";
import {Instruction} from "../../../../api/WriterAPI";
import {css} from "@emotion/css";
import {ChooseTest} from "./ChooseTest";
import {useDarkMode} from "../../../../misc/UseGrey";
import {WriterContext} from "../../WriterProvider";
import {useNavigate} from "react-router-dom";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {LoginPicker} from "./LoginPicker";
import {MyTextarea} from "./MyTextarea";

interface DragItem {
    index: number
    id: string
    type: string
}

export type InstructionWithUuid = Instruction & {uuid: string}

export function InstructionRow(props: {
    index: number;
    value: InstructionWithUuid;
    autoFocus?: boolean;
    onEnterPress(): void;
    onFocusClear(): void;
    onRemove(): void;
    onChange(index: number, value: InstructionWithUuid): void;
    reOrder(dragIndex: number, hoverIndex: number): void;
}) {
    const ins = props.value;
    const {reOrder, index, autoFocus} = props;
    const id = ins.uuid;

    const ref = useRef<HTMLDivElement>(null)
    const [_, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            reOrder(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0.4 : 1

    const isDark = useDarkMode();
    const [showRunSelect, setShowRunSelect] = useState<number|null>(null)
    const wrCtx = useContext(WriterContext);
    const nav = useNavigate();
    drag(drop(ref))

    return (
        <>
            {showRunSelect !== null && <ChooseTest onCancel={() => setShowRunSelect(null)} onSelect={value => {
                if(!value || showRunSelect === null) return;

                props.onChange(index, Object.assign({}, ins, {
                    runFile: value.id,
                    value: value.name,
                }))

                setShowRunSelect(null)
            }} />}
            <div ref={ref} style={{
                opacity: opacity,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: "1px solid " + grey["200"],
            }}>
                <div ref={drag} style={{
                    cursor: 'move',
                    fontSize: "1rem",
                    color: grey["300"],
                    paddingTop: 6,
                }}>
                    <DragIndicatorIcon fontSize="inherit" color="inherit" />
                </div>

                <div style={{fontSize: "0.8rem", paddingRight: 8, paddingLeft: 8}}>
                    {index + 1}.
                </div>
                <Select size="small" style={{width: 100}} className={selectCss} value={ins.type || "-"} onChange={e => {
                    let value = e.target.value as any
                    if (value === "-") value = ""

                    props.onChange(index, {
                        type: value,
                        value: "",
                        uuid: ins.uuid,
                    })
                }}>
                    <MenuItem value={"-"}>Type</MenuItem>
                    <MenuItem value={"login"}>Login as</MenuItem>
                    <MenuItem value={"runFile"}>Run Test</MenuItem>
                    <MenuItem value={"step"}>Action</MenuItem>
                    <MenuItem value={"verify"}>Verify</MenuItem>
                </Select>
                {ins.type === "runFile" ?
                    <>
                        <button
                            style={{
                                flex: 1,
                                fontSize: "0.8rem",
                                paddingLeft: 8,
                                textAlign: "left",
                                background: "none",
                                border: "none"
                            }}
                            onClick={() => {
                                setShowRunSelect(index)
                            }}>
                            {(ins.runFile && wrCtx.fullNameForId(ins.runFile)) || <i>Select a test to run</i>}
                        </button>
                        <div>
                            <button className={iconButtonCss}
                                    onClick={() => {
                                        setShowRunSelect(index)
                                    }}
                                    style={{color: isDark ? grey["300"] : grey["800"]}}>
                                <PageviewIcon fontSize="inherit" color="inherit"/>
                            </button>
                        </div>
                        <div>
                            <button className={iconButtonCss}
                                    onClick={() => {
                                        if (!ins.runFile) return;
                                        nav(wrCtx.getUrl(ins.runFile))
                                    }}
                                    style={{color: isDark ? grey["300"] : grey["800"]}}>
                                <OpenInNewIcon fontSize="inherit" color="inherit"/>
                            </button>
                        </div>
                    </> :
                    ins.type === "login" ? <LoginPicker value={ins} onChange={value => {
                            props.onChange(index, value);
                    }} /> :
                    <MyTextarea ref={r => {
                        if(autoFocus && r) {
                            r.focus()
                            props.onFocusClear()
                        }
                    }} onKeyDown={e => {
                        if(e.key === "Enter") {
                            e.preventDefault()
                            props.onEnterPress()
                        }
                    }} placeholder={
                        ins.type === "step" ? "What should I do?"
                            : ins.type === "verify" ? "that..."
                            : "..."} className={inputCss}
                           value={ins.value}
                           onChange={e => {
                               props.onChange(index, Object.assign({}, ins, {
                                  value: e.target.value,
                               }))
                           }}
                    />}

                <div>
                    <button className={iconButtonCss} onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.onRemove();
                    }}>
                        <Close fontSize="inherit" color="inherit"/>
                    </button>
                </div>
            </div>
        </>
    )
}

const iconButtonCss = css({
    border: "none",
    background: "none",
    cursor: "pointer",
    padding: 8,
    color: red["300"],
    fontSize: "0.8rem",
    height: "1em",

    "&:hover": {
        color: red["600"],
    }
})

const selectCss = css({
    "& [role=combobox]": {
        minHeight: 0,
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: "0.8rem",
        border: "none",
    },
    "& fieldset" : {
        border: "none",
    }
})

const inputCss = css({
    border: "none",
    padding: 8,
    fontSize: "0.8rem",
    flex: 1
})